












































































import { formatRupiah } from "@/apps/core/modules/utils/text";
import { computed, defineComponent, ref } from "@vue/composition-api";
import Vue from "vue";
import { PengajuanFM } from "../models/pengajuanForm";
import { listVM, pengajuanState } from "../modules/stores/pengajuan";
import useFormModel from "@/apps/core/modules/useFormModel";
import router from "@/router";
import { Pengajuan } from "../models/pengajuan";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";
import { SaveContext } from "@/apps/core/models/formModel";

export default defineComponent({
  name: "PengajuanBerkasForm",
  components: {
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const initialValue = computed(() => pengajuanState.pengajuanFM?.instance);
    const produkName = pengajuanState.produkName;
    if (!pengajuanState.agree || !initialValue.value) {
      Vue.nextTick().then(() => {
        router.replace({ name: "pinjaman-produk-list" });
      });
    }

    const pengajuanInit = initialValue.value as Pengajuan;
    const initValues = {
      produk: pengajuanState.produk,
      nilai: pengajuanInit.nilai,
      tenor: pengajuanInit.tenor,
    };
    const form = new PengajuanFM(initValues, [], ["dokLIst"]);
    form.validity.edited = true;
    form.validity.validated = true;
    const dokList = ref([] as Array<Record<string, any>>);
    const composition = useFormModel(form);
    Object.assign(composition.formRef.instance, pengajuanInit);
    const bgImageUrl = require("@/apps/pinjaman/assets/img/pengajuan_bg.png");
    const pengajuan = composition.instance as Pengajuan;
    const uploadPhotoList = [] as Array<Record<string, any>>;
    const pushBerkas = (file: File) => {
      const uploadPhoto = new UploadPhoto("pengajuan");
      // uploadPhoto.requestAWS(null, null);
      const dok = {
        berkasFile: file,
        filename: file.name,
      } as Record<string, any>;
      dokList.value.push(dok);
      // form.validate("dokList");
      uploadPhotoList.push({ file: file, uploadPhoto: uploadPhoto });
    };

    const formValid = computed(
      () => pengajuanState.agree // dokList.value.length > 0
    );
    // const saveForm = () => form.save();
    const saveForm = async () => {
      await form.save(SaveContext.Create, false);
      if (form.getIsValid().value) {
        listVM.setRefreshRequest("pengajuan");
        router.replace({ name: "pinjaman-list" });
        pengajuanState.reset();
      }
    };

    let uploadCount = 0;
    const reqAws = (dt: Record<string, any>) => {
      dt.uploadPhoto.requestAWS(
        dt.file,
        async (url: string | null, isImage: true) => {
          if (pengajuan.dokList === null) {
            pengajuan.dokList = [];
          }
          const dok = {
            berkas: url,
            'is_image': isImage,
          } as Record<string, any>;
          pengajuan.dokList.push(dok);
          form.validate("dokList");
          uploadCount++;
          if (uploadCount === uploadPhotoList.length) {
            saveForm();
            composition.formRef.isLoading = false;
          } else {
            reqAws(uploadPhotoList[uploadCount]);
          }
        }
      );
    };

    const upload = () => {
      composition.formRef.isLoading = true;
      if (dokList.value.length === 0) {
        saveForm();
      } else {
        reqAws(uploadPhotoList[uploadCount]);
      }
    };

    return {
      // Data
      bgImageUrl,
      dokList,
      formatRupiah,
      initialValue,
      produkName,

      // Computed
      formValid,

      // Composition
      ...composition,

      // Method
      pushBerkas,
      upload,
    };
  },
});
